.border-render {
    border-style: dotted;
    border-color: rgb(163, 161, 161);
    border-top-width: 0px;
}

input[type="radio"] {
    border: 0px;
    width: 80%;
    height: 1.5em;
}

div.scrollingDivField {
    left: -1px;
    height: 920px;
    overflow: scroll;
    overflow-x: hidden;
}

.inputMask {
    height: 60px !important;
}

label {
    color: white;
}

div.scrollingDivDocs {
    left: -1px;
    height: 650px;
    overflow: scroll;
    overflow-x: hidden;
}

/*!
Site: Servicios en Línea
Author: Arsen T. Holsteinson
Version: 1.0
*/
a {
    cursor: pointer;
    text-decoration: none;
    color: #0e38b1;
}

ul,
ol {
    margin: 0;
    padding: 0;
}

ul {
    margin-bottom: 1rem;
    margin-left: 1.25rem;
    list-style-type: disc;
    list-style-position: outside;
    line-height: 1.6;
}

button {
    cursor: pointer;
}

img {
    max-width: 100%;
}

canvas,
video,
img,
svg {
    vertical-align: middle;
}

video,
canvas {
    width: 100%;
    height: auto;
}

hr {
    margin: 0;
    height: 0;
    clear: both;
    border: 0;
    border-bottom: 1px solid rgba(157, 157, 157, 0.35);
}

.list-none {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.list-circle li:before {
    margin-left: -20px;
    padding-right: 1rem;
    font-family: "icomoon";
    content: "";
    font-size: 8px;
    font-size: 0.5rem;
    color: #0e38b1;
}

.align-right {
    text-align: right;
}

.align-center {
    text-align: center;
}

.hidden-header {
    height: 1px;
    width: 1px;
    margin: 0;
    padding: 0;
    visibility: hidden;
}

.msg-404 {
    padding: 2rem;
    text-align: center;
    color: #a6bdff;
}

.msg-404 span {
    display: block;
    font-size: 72px;
    font-size: 4.5rem;
    font-weight: 900;
}

.section-heading {
    margin: 0 0.5rem 0 0;
    font-weight: 500;
    text-transform: uppercase;
    color: #0e38b1;
}

.page-content {
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 0.2rem 0;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(109, 110, 113, 0.1);
}

.page-content:after {
    content: " ";
    display: block;
    height: 0;
    clear: both;
}

.page-padding {
    padding: 1rem;
}

.service-request-heading h3 {
    padding: 0.5rem 1rem;
    background: #fff;
    border: 1px solid #d1ddff;
    font-size: 18px;
    font-size: 1.125rem;
}

.service-request-heading h3 span {
    color: #9d9d9d;
}

.btn {
    margin: 0.5rem 0.25rem;
    padding: 1rem 1.25rem;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    border-radius: 4px;
    font-size: 12px;
    font-size: 0.75rem;
    text-transform: uppercase;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    background: #d1ddff;
    border: 1px solid #a6bdff;
    box-shadow: 0px 7px 17px rgba(166, 189, 255, 0.22);
    color: #000;
    background: #d1ddff;
}

.btn:hover,
.btn:active {
    background: #a6bdff;
}

.btn-primary {
    background: linear-gradient(180deg, #4bb543 0%, #53a602 100%);
    border: 1px solid #4bb543;
    box-shadow: 0px 7px 17px rgba(75, 181, 67, 0.22);
    color: #fff;
    background: linear-gradient(180deg, #4bb543 0%, #53a602 100%);
}

.btn-primary:hover,
.btn-primary:active {
    background: linear-gradient(0, #4bb543 0%, #53a602 100%);
}

.btn-secondary {
    border-radius: 7px;
    background: linear-gradient(45deg, #0b3ac2 0%, #2d5ce2 100%);
    border: 1px solid transparent;
    box-shadow: 0px 7px 17px rgba(14, 56, 177, 0.22);
    color: #fff;
    background: linear-gradient(45deg, #0b3ac2 0%, #2d5ce2 100%);
}

.btn-secondary:hover,
.btn-secondary:active {
    background: linear-gradient(45deg, #2d5ce2 0%, #0b3ac2 100%);
}

.btn-primary [class^="icon-"]:before,
.btn-secondary [class^="icon-"]:before {
    color: #fff;
}

.btn-hollow {
    padding: 0.875rem 1.25rem;
    font-size: 14px;
    font-size: 0.875rem;
    text-transform: none;
    background: #fff;
    border: 1px solid #f01;
    box-shadow: 0px 7px 17px rgba(0, 0, 0, 0.22);
    color: #6d6e71;
    background: #fff;
    border-color: #f01;
}

.btn-hollow:hover,
.btn-hollow:active {
    background: #fff;
    color: #f01;
}

.btn-hollow [class^="icon-"]:before {
    color: #6d6e71;
}

.btn-hollow:hover,
.btn-hollow:active {
    background: #fff;
    color: #f01;
}

.btn-hollow-secondary {
    border-color: #0e38b1;
}

.btn-hollow-secondary:hover,
.btn-hollow-secondary:active {
    color: #0e38b1;
}

.btn-hollow-tertiary {
    padding-left: 1rem;
    padding-right: 1rem;
    text-transform: uppercase;
    border-color: #9d9d9d;
    color: #000;
}

.btn-hollow-tertiary:hover,
.btn-hollow-tertiary:active {
    color: #0e38b1;
}

.btn-small {
    margin: 0.25rem;
    padding: 0.75em 1.5em;
    font-size: 12px;
    font-size: 0.75rem;
    text-transform: none;
    box-shadow: none;
}

.btn-large {
    margin: 0 0 0.75rem 0.75rem;
    padding: 1.5rem 1.25rem;
    display: inline-block;
    border-radius: 4px;
    background: #d1ddff;
    border: 1px solid #0e38b1;
    box-shadow: 0px 7px 17px rgba(166, 189, 255, 0.22);
    color: #0e38b1;
    background: #d1ddff;
}

.btn-large [class^="icon-"]:before {
    margin-right: 0.5rem;
    font-size: 24px;
    font-size: 1.5rem;
    vertical-align: middle;
    color: #0e38b1;
}

.btn-large:hover,
.btn-large:active {
    background: #a6bdff;
}

.btn-no-shadow {
    box-shadow: none;
}

button.btn,
input[type="submit"].btn,
input[type="reset"].btn {
    padding: 1.23rem 1rem;
    cursor: pointer;
}

button.btn-hollow,
input[type="submit"].btn-hollow,
input[type="reset"].btn-hollow {
    padding: 1.1rem 1.35rem;
}

button.btn-small,
input[type="submit"].btn-small,
input[type="reset"].btn-small {
    padding: 0.75rem 1rem;
    font-size: 11px;
    font-size: 0.6875rem;
}

button.btn-hollow.btn-small,
input[type="submit"].btn-hollow.btn-small,
input[type="reset"].btn-hollow.btn-small {
    padding: 0.7rem 1.25rem;
    font-size: 11px;
    font-size: 0.6875rem;
}

.btn-disabled {
    pointer-events: none;
    opacity: 0.5;
}

.btn-icon {
    margin: 0.5rem 0.25rem;
    display: inline-block;
    padding: 8px 12px;
    border-radius: 4px;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    appearance: none;
    background-color: #f2f6ff;
}

.btn-icon [class^="icon-"]:before {
    color: #0e38b1;
}

.btn-icon.active,
.btn-icon:hover {
    background-color: #0e38b1;
    color: #fff;
}

.btn-icon.active [class^="icon-"]:before,
.btn-icon:hover [class^="icon-"]:before {
    color: #fff;
}

.btn-icon.active {
    pointer-events: none;
}

.btn-file-select {
    height: 50px;
    margin-top: 0;
    margin-left: 0;
    padding: 1rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    min-width: 60%;
    max-width: 75%;
}

.btn-file-select [class^="icon-"]:before {
    margin-right: 0.5rem;
    font-size: 18px;
    font-size: 1.125rem;
    vertical-align: middle;
}

.btn-back {
    margin-right: 0.5rem;
    font-size: 48px;
    font-size: 3rem;
    vertical-align: middle;
    opacity: 0.5;
}

.btn-back:hover {
    opacity: 1;
}

.btn-send-message {
    margin-bottom: 0.55rem;
    margin-right: 0.5rem;
    padding: 0.5rem 2rem;
    display: inline-block;
    border: none;
    border-radius: 4px;
    background: #0e38b1;
    text-align: center;
}

.btn-send-message [class^="icon-"]:before {
    font-size: 16px;
    font-size: 1rem;
    color: #fff;
}

.btn-attach {
    padding: 0.6rem 0.55rem;
    display: inline-block;
    border: 1px solid #d1ddff;
    border-radius: 4px;
    font-size: 11px;
    font-size: 0.6875rem;
}

.btn-attach [class^="icon-"]:before {
    margin-right: 0.25rem;
    vertical-align: middle;
    font-size: 12px;
    font-size: 0.75rem;
}

.btn-311 {
    width: 285px;
    padding: 1.5rem;
    display: inline-block;
    border-radius: 4px;
    border: 1px solid #f01;
    background: snow;
    color: #1f1f21;
}

.btn-311 span {
    width: 120px;
    margin-right: 0.75rem;
    vertical-align: middle;
    display: inline-block;
}

.btn-311 img {
    width: 100px;
    display: inline-block;
}

.btn-311:hover {
    color: #f01;
}

.tabs-module {
    clear: both;
}

.tabs-module .tab-btn-group {
    margin-bottom: -1px;
}

.tabs-module .tab-btn {
    padding: 1rem;
    display: inline-block;
    text-align: center;
    border: 1px solid transparent;
    border-bottom: none;
    color: #0e38b1;
}

.tabs-module .tab-btn span {
    display: none;
}

.tabs-module .tab-btn.btn-active {
    background-color: #fcfcfc;
    border-color: rgba(157, 157, 157, 0.25);
    border-radius: 4px 4px 0 0;
}

.tabs-module .tab-btn.btn-active [class^="icon-"]:before,
.tabs-module .tab-btn.btn-active [class*=" icon-"]:before {
    color: #0e38b1;
}

.tabs-module .tab-btn:hover {
    color: #092473;
}

.tabs-module .tab-btn [class^="icon-"],
.tabs-module .tab-btn [class*=" icon-"] {
    margin-right: 0.5rem;
    display: inline-block;
    vertical-align: middle;
    font-size: 19px;
    font-size: 1.1875rem;
}

.tabs-module .tab {
    padding: 1.5rem 1rem;
    display: none;
}

.tabs-module .tab.active {
    border-radius: 4px;
    background-color: #fcfcfc;
    border: 1px solid rgba(157, 157, 157, 0.25);
    display: block;
}

.tabs-module.tabs-light .tab-btn.btn-active,
.tabs-module.tabs-light .tab.active {
    background-color: #fff;
}

.tabs-module.tabs-small .tab-btn {
    padding: 0.75rem 1rem;
    font-size: 14px;
    font-size: 0.875rem;
}

.tabs-module.tabs-no-padding .tab {
    padding: 0.5rem 0.25rem;
    border-right: none;
    border-bottom: none;
    border-left: none;
}

.tabs-module.tabs-no-padding .tab-btn {
    border-top-color: transparent;
}

.tabs-module.tabs-no-padding .tab-btn:first-child {
    border-left-color: transparent;
}

form {
    margin: 0 0 1.5em 0;
}

fieldset {
    margin: 0 0 2.5rem 0;
    padding: 0;
    border: none;
}

fieldset legend {
    width: 100%;
    margin-bottom: 2.5rem;
    font-weight: 700;
}

label {
    display: block;
    margin-bottom: 0.75rem;
    color: #1f1f21;
}

textarea,
select,
input[type="text"],
input[type="password"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="tel"] {
    width: 100%;
    margin-bottom: 1.8em;
    padding: 1rem 1rem;
    display: block;
    appearance: none;
    outline: none;
    font-size: 17px;
    font-size: 1.0625rem;
    background-color: #fff;
    box-shadow: none;
    border: 1px solid rgba(157, 157, 157, 0.25);
    box-shadow: 0 2px 12px rgba(157, 157, 157, 0.05);
    border-radius: 4px;
    color: #6d6e71;
}

textarea:disabled,
select:disabled,
input[type="text"]:disabled,
input[type="password"]:disabled,
input[type="date"]:disabled,
input[type="number"]:disabled,
input[type="email"]:disabled,
input[type="tel"]:disabled {
    background-color: #f7f7f7;
}

textarea:focus,
select:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="date"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus {
    background-color: #f2f6ff;
}

input[type="radio"],
input[type="submit"],
input[type="reset"] {
    appearance: none;
}

input[type="radio"] {
    opacity: 0;
}

input[type="radio"] + label {
    position: relative;
    font-size: 13px;
    font-size: 0.8125rem;
}

input[type="radio"] + label:before {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    padding: 0;
    content: "";
    vertical-align: middle;
    display: inline-block;
    background: #fff;
    border: 2px solid rgb(2, 20, 180);
    border-radius: 4px;
    line-height: 20px;
}

input[type="radio"] + label:before {
    border-radius: 100%;
}

input[type="radio"]:checked + label:before {
    color: #68d201;
    content: "✓";
    text-align: center;
    font-size: 16px;
    font-size: 1rem;
    font-weight: 700;
}

input[type="radio"]:checked + label:before {
    content: " ";
}

input[type="radio"]:checked + label:after {
    width: 12px;
    height: 12px;
    left: 5px;
    top: 5px;
    content: " ";
    position: absolute;
    border-radius: 100%;
    background-color: #68d201;
}

input[type="radio"]:focus + label::before {
    outline: #9d9d9d auto 5px;
}

input[type="search"] {
    width: 100%;
}

.form-select {
    width: 100%;
    position: relative;
}

.form-select select {
    display: inline-block;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
}

.form-select:after {
    content: "▼";
    padding: 0 15px;
    position: absolute;
    top: 11px;
    right: 0;
    z-index: 9;
    color: #0e38b1;
    font-size: 21px;
    font-size: 1.3125rem;
}

input[type="file"] {
    opacity: 0;
}

textarea {
    min-height: 150px;
    resize: vertical;
}

progress {
    appearance: none;
    border: none;
    background-size: auto;
    height: 62px;
    width: 100%;
}

progress[value]::-webkit-progress-bar {
    background-color: #fff;
}

progress::-moz-progress-bar {
    background: #fff;
}

progress::-webkit-progress-value {
    background: rgba(104, 210, 1, 0.15);
}

/*progress::-moz-progress-value {*/
/*	background: rgba(104, 210, 1, .15)*/
/*}*/

form p {
    margin: 1rem 0 2rem 0;
}

.form-inner {
    padding: 0.5rem;
}

.legend-styled {
    margin-bottom: 1rem;
    padding: 0.5rem;
    display: block;
    text-transform: uppercase;
    background-color: #f2f6ff;
    color: #0e38b1;
}

.legend-styled-fs {
    font-size: 15px;
    border-bottom: 1px solid #0e38b1;
    color: #0e38b1;
    margin-bottom: 1rem;
    padding: 0.5rem;
    display: block;
    text-transform: uppercase;
    background-color: #f2f6ff;
}

.form-actions {
    margin-top: 2rem;
    text-align: right;
}

.form-actions .form-actions-buttons {
    display: inline-block;
}

.form-prompt {
    float: left;
    padding: 1rem;
}

.form-field-note {
    font-size: 14px;
}

label span {
    color: #9d9d9d;
}

.field-required span {
    color: #e0000f;
}

.form-field-link {
    margin-bottom: 1rem;
    display: inline-block;
    font-size: 14px;
    font-size: 0.875rem;
}

.form-field-link span:before {
    margin-right: 0.25rem;
    vertical-align: middle;
    color: #0e38b1;
}

.form-field-link:hover {
    text-decoration: underline;
}

.input-error textarea,
.input-error select,
.input-error
input[type]:not([type="file"]):not([type="radio"]):not([type="submit"]):not([type="reset"]):not([type="hidden"]) {
    border: 1px dotted #f01;
    color: #f01;
}

.input-error .error-prompt {
    display: block;
    float: right;
    color: #f01;
    opacity: 0.7;
    font-size: 11px;
    font-size: 0.6875rem;
    font-style: normal;
}

.input-error .error-prompt:after {
    width: 2.1em;
    margin-left: 0.25rem;
    margin-top: 0.5em;
    padding: 0.2rem 0;
    display: inline-block;
    content: "!";
    text-align: center;
    border-radius: 100%;
    font-size: 10px;
    font-size: 0.625rem;
    font-weight: 700;
    background: #f01;
    color: #f01;
}

.input-success textarea,
.input-success select,
.input-success
input[type]:not([type="file"]):not([type="radio"]):not([type="submit"]):not([type="reset"]):not([type="hidden"]) {
    border: 1px dotted #60d55c;
    color: #60d55c;
}

.input-success .success-prompt {
    display: block;
    float: right;
    color: #60d55c;
    opacity: 0.7;
    font-size: 11px;
    font-size: 0.6875rem;
    font-style: normal;
}

.input-success .success-prompt:after {
    width: 2.1em;
    margin-left: 0.25rem;
    margin-top: 0.5em;
    padding: 0.2rem 0;
    display: inline-block;
    content: "!";
    text-align: center;
    border-radius: 100%;
    font-size: 10px;
    font-size: 0.625rem;
    font-weight: 700;
    background: #60d55c;
    color: #60d55c;
}

.success-prompt {
    float: right;
    display: block;
    font-style: normal;
    color: #60d55c;
}

.success-prompt span {
    color: #60d55c;
}

.err-prompt {
    float: right;
    display: block;
    font-style: normal;
    color: #f01;
}

.err-prompt span {
    color: #f01;
}

.loading-prompt {
    float: right;
    font-size: 14px;
    font-size: 0.875rem;
    color: #6d6e71;
}

.form-steps {
    margin-bottom: 1rem;
}

.form-steps .step {
    padding: 1rem;
    display: none;
    border: 1px solid rgba(0, 0, 0, 0.03);
    border-bottom: 4px solid #f7f7f7;
    text-align: center;
    color: #000;
}

.form-steps .step.active {
    display: block;
    background-color: #f6f9fc;
    border-bottom-color: #68d201;
    color: #0e38b1;
    opacity: unset;
}

.form-steps .step p {
    margin: 0;
    font-size: 14px;
}

.modal-form-prompt {
    display: inline-block;
    font-size: 12px;
    color: #f01;
}

.form-paragraph {
    color: gray;
    opacity: 0.8;
}

.form-result h3 {
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
    font-size: 1.25rem;
    border-bottom: 1px solid #4bb543;
    color: #4bb543;
}

.form-result h3 p {
    color: #000;
}

.form-result.result-error h3 {
    border-bottom: 1px solid #f01;
    color: #f01;
}

.dashboard-services-filter fieldset {
    margin-bottom: 1rem;
}

.dashboard-services-filter fieldset legend {
    margin-bottom: 0.25rem;
}

.dashboard-services-filter select,
.dashboard-services-filter input[type="text"] {
    padding: 0.75rem;
    font-size: 14px;
    font-size: 0.875rem;
}

.dashboard-services-filter .form-select:after {
    top: 5px;
}

.hentry .entry-title {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 600;
}

.hentry .page-padding {
    padding-bottom: 0;
}

.hentry .post-meta-date {
    font-size: 14px;
    font-size: 0.875rem;
    color: #6d6e71;
}

.hentry .entry-subtitle {
    margin-top: 0;
    font-weight: 600;
    color: #6d6e71;
}

.sidebar h4 {
    text-transform: uppercase;
    letter-spacing: 0;
    font-size: 17px;
    font-size: 1.0625rem;
    font-weight: 600;
    color: #0e38b1;
}

.list-guia,
.list-guia-interior {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    list-style-type: none;
}

.list-guia {
    margin: 0;
    padding: 0;
}

.list-guia li {
    width: 100%;
    margin-bottom: 1rem;
    font-size: 14px;
    font-size: 0.875rem;
}

.list-guia li .list-guia-interior {
    height: 100%;
    border: 1px solid rgba(157, 157, 157, 0.35);
}

.list-guia li .list-guia-interior .list-guia-icon {
    width: 35%;
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
    align-self: center;
    text-align: center;
}

.list-guia li .list-guia-interior .list-guia-icon span {
    font-size: 52px;
    font-size: 3.25rem;
    color: #6d6e71;
}

.list-guia li .list-guia-interior .card-header {
    max-width: 120px;
    height: 50px;
    margin: 0 auto 0.5rem auto;
    border-radius: 5px;
}

.list-guia li .list-guia-interior .card-header.interactivos {
    background-color: #4bb543;
    background-image: linear-gradient(to bottom, #4bb543, #53a602);
}

.list-guia li .list-guia-interior .card-header.transaccionales {
    background-color: #1249e8;
    background-image: linear-gradient(to bottom, #466dd9, #1249e8);
}

.list-guia li .list-guia-interior .list-guia-content {
    /*-webkit-box-flex: 0 0 65%;*/
    /*-moz-box-flex: 0 0 65%;*/
    -webkit-flex: 0 0 65%;
    -ms-flex: 0 0 65%;
    flex: 0 0 65%;
    max-width: 66%;
    padding: 1rem;
    border-left: 1px solid rgba(157, 157, 157, 0.35);
}

.grid {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr;
}

.grid:after {
    content: " ";
    display: block;
    height: 0;
    clear: both;
}

.grid:after {
    display: none;
}

.grid.grid-six {
    grid-column-gap: 0.1em;
    grid-template-columns: repeat(2, 2fr);
}

.grid.grid-six .col {
    width: 48%;
    display: inline-block;
}

@supports (display: grid) {
    .grid > * {
        width: auto !important;
        margin: 0 !important;
        display: block;
    }
}

.wrapper {
    padding: 10px 8px;
}

.pagination {
    margin: 0;
    padding: 2rem 0;
    text-align: center;
    line-height: 2;
    list-style: none;
}

.pagination li {
    display: inline-block;
}

.pagination li:first-child a {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.pagination li:last-child a {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.pagination a {
    margin-right: -1px;
    padding: 0.2rem 1rem;
    display: block;
    border: 1px solid rgba(109, 110, 113, 0.35);
    font-size: 13px;
    font-size: 0.8125rem;
    background: #fff;
    color: #6d6e71;
}

.pagination a:hover,
.pagination a.current {
    background-color: #f01;
    background-image: linear-gradient(180deg, #ff0011 0%, #e0000f 100%);
    color: #fff;
}

.spinner {
    margin: 2rem auto;
    display: block;
    height: 60px;
    width: 60px;
    -webkit-animation: rotation 0.6s infinite linear;
    -moz-animation: rotation 0.6s infinite linear;
    -o-animation: rotation 0.6s infinite linear;
    animation: rotation 0.6s infinite linear;
    border: 6px solid rgba(209, 221, 255, 0.5);
    border-top: 6px solid #a6bdff;
    border-radius: 100%;
}

@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
    }
}

@-moz-keyframes rotation {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(359deg);
    }
}

@-ms-keyframes rotation {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(359deg);
    }
}

@-o-keyframes rotation {
    from {
        -o-transform: rotate(0deg);
    }
    to {
        -o-transform: rotate(359deg);
    }
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.spinner.small {
    margin: 1rem auto;
    height: 40px;
    width: 40px;
}

.spinner.tiny {
    margin: 0 0 0 0.25rem;
    height: 20px;
    width: 20px;
    display: inline-block;
    vertical-align: middle;
}

.spinner.large {
    margin: 4rem auto 0 auto;
    height: 120px;
    width: 120px;
}

.spinner-text {
    margin-bottom: 4rem;
    text-align: center;
}

.jssocials-share-label {
    display: inline-block;
    text-indent: -9999px;
    width: 0;
    height: 0;
}

.form-field .react-datepicker-wrapper,
.form-field .react-datepicker__input-container {
    display: block;
}

.label-note {
    color: #0e38b1;
    font-size: 20px;
}

.react-datepicker-popper select {
    width: auto;
    margin-bottom: 0;
    padding: 0;
    /*appearance: menulist;*/
    font-size: 14px;
    background-color: transparent;
    border: none;
    box-shadow: none;
    border-radius: 0;
    color: #000;
}

.react-datepicker-popper select:disabled {
    background-color: transparent;
}

.react-datepicker-popper select:focus {
    background-color: transparent;
}

#fallbackCamera,
#fallbackResult {
    margin-bottom: 1rem;
}

.form-field .react-datepicker__close-icon {
    position: absolute;
    top: 50%;
    right: 0;
}

.form-field .react-datepicker__close-icon::after {
    top: 22%;
}

.react-rater {
    margin-left: 0.75rem;
    font-size: 1.25rem;
}

.react-rater .react-rater-star.is-active,
.react-rater .react-rater-star.is-active-half:before,
.react-rater .react-rater-star.will-be-active {
    color: #fc6;
}

@supports (-ms-ime-align: auto) {
    .sponsors-list .col-interior {
        height: 135px;
    }
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    img {
        border: none;
    }

    .heading-info-card .card-heading p {
        clear: both;
    }

    .sponsors-list .col-interior {
        height: 135px;
    }

    .sponsors-list .col-interior div {
        width: 180px;
        height: 135px;
    }

    .sponsors-list .col-interior img {
        width: 100%;
        height: auto;
    }

    .header .branding-nav .brand img,
    .footer-copyright .brand img,
    .heading-info-card .card-image img {
        height: auto;
    }

    .list-guia {
        -ms-flex-wrap: wrap;
    }

    .form-fields {
        -ms-flex-wrap: wrap;
    }

    .form-fields .form-field {
        margin-right: 1%;
    }
}

.s-solicitud .s-solicitud-image img {
    height: auto;
}

select::-ms-expand {
    display: none;
}

@media screen and (min-width: 0\0
) and (min-resolution: 0.001dpcm) {
    img {
        border: none;
    }

    .category-nav li {
        float: left;
    }

    .header .branding-nav .brand img,
    .footer-copyright .brand img,
    .heading-info-card .card-image img {
        height: auto;
    }

    .form-fields .form-field {
        width: 49%;
        float: left;
        margin-right: 1%;
    }

    .form-fields .form-field.field-single {
        width: 100%;
        clear: both;
    }
}

@-moz-document url-prefix() {
    .header .branding-nav .main-nav a:after {
        bottom: -39px;
    }
}

