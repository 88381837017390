#modal-body-card-form {
    max-height: calc(100vh - 210px);
    overflow-y: auto;
}

.center {
    margin: 0px auto;
    width: 350px;
    display: none;
}

/* Important part */
.modal-dialog {
    overflow-y: initial !important;
}

.modal-body-grid {
    height: 600px;
    overflow-y: auto;
}
