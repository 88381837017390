.border {
    border-style: dotted;
    background-color: rgb(240, 240, 240);
    padding: 10px;
}

.radius {
    border-radius: 5px;
}

.selected-component {
    border-style: solid;
    border-color: rgb(19, 185, 27);
}

.cursor {
    cursor: pointer;
}

.padding-rule {
    padding: 30px;
}

input[type="checkbox"] {
    transform: scale(2);
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    background: lightgray;
    height: 16px;
    width: 16px;
    border: 1px solid white;
    border-radius: 30%;
}

input[type="checkbox"]:checked {
    background: #00695c;
}

input[type="checkbox"]:hover {
    filter: brightness(90%);
}

input[type="checkbox"]:disabled {
    background: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
}

input[type="checkbox"]:after {
    content: "";
    position: relative;
    left: 35%;
    top: 18%;
    width: 35%;
    height: 50%;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    display: none;
}

input[type="checkbox"]:checked:after {
    display: block;
}

input[type="checkbox"]:disabled:after {
    border-color: #7b7b7b;
}

.btn-float {
    border-radius: 50%;
    border: 0 solid;
    box-shadow: 5px 5px 5px rgb(167, 167, 167);
    font-size: 38px;
    margin: 4px 3px;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    width: 90px;
}

.btn-label {
    color: white;
    font-size: 14px;
    padding: 8px;
    border-radius: 14px;
    background-color: rgba(0, 0, 0, 0.7);
}

.flotante-label {
    position: fixed;
    top: 91%;
    left: 300px;
    opacity: 0;
    transition: opacity 0s, margin-top 0.1s;
}

.flotante-label-hover {
    position: fixed;
    top: 85%;
    left: 91.5%;
    opacity: 1;
    transition: opacity 0s, margin-top 0.1s;
}

.flotante {
    position: fixed;
    top: 90%;
    left: 92%;
}

@media only screen and (max-width: 1205px) {
    .flotante {
        position: fixed;
        top: 90%;
        left: 88%;
    }
}

@media only screen and (max-height: 755px) {
    .flotante {
        position: fixed;
        top: 86%;
    }

    .flotante-label {
        position: fixed;
        top: 91%;
        left: 300px;
        opacity: 0;
        transition: opacity 0s, margin-top 0.1s;
    }

    .flotante-label-hover {
        position: fixed;
        top: 80%;
        left: 91.5%;
        opacity: 1;
        transition: opacity 0s, margin-top 0.1s;
    }
}
